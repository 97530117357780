import styled, { css } from "styled-components";
import { Form, Input, Select } from "antd";
const { TextArea } = Input;
import _PButton from "../../../components/PButton";

export const StyledLabel = styled.label`
  color: ${props => props.$darkLabels ? 'black' : 'white'};
  font-size: 12px !important;
  display: block;
  margin-bottom: 4px;
  line-height: 1.2;
  width: 100%;

  .dark-labels & {
    color: black;
  }
`;

export const PButton = styled(_PButton)`
  border-radius: 0px;
  background-color: white;
  padding: 10px !important;
  height: unset;
  box-shadow: none;
  border: 2px solid ${({ theme }) => theme.colors.classyRed};
  width: 100% !important;
  display: block !important;
  margin: 0;
  position: relative;

  span {
    color: ${({ theme }) => theme.colors.classyRed};
    font-weight: bold;
    font-size: 1.25rem;
    font-family: "Roboto Bold";
    width: 100%;
    text-align: center;
    display: inline-block !important;
  }

  &:disabled {
    background-color: white;
    opacity: 0.9;
    cursor: not-allowed;
  }
`;

export const FormDiv = styled.div`
  flex: 1 1 0px;
  min-height: fit-content;
  background-color: white;
  padding: 0.2em;
  border: 0.1rem solid black;
  .form {
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    display: block;
    width: 100%;
    padding: 10%;
    position: relative;
  }
`;

export const FormContainer = styled.div`
  && {
    max-width: ${props => props.$formWidth || 'calc(100% - 0px)'};
    margin: 0 auto;
    background-color: ${props => props.$backgroundColor || 'transparent'};
    
    // Set label color CSS variable based on background
    ${props => {
      const bg = props.$backgroundColor;
      const isBurgundy = bg === '#762738' || bg === 'rgba(118, 39, 56, 1)';
      return isBurgundy ? `
        --form-label-color: white;
      ` : `
        --form-label-color: black;
      `;
    }}
    
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
    
    .ant-form-item {
      margin-bottom: 6px !important;
      margin-top: 0 !important;
      display: flex !important;
      flex-direction: column !important;

      &[hidden], &.ant-form-item-hidden {
        display: none !important;
        margin: 0 !important;
        padding: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
      }
    }
    
    .ant-form-item-label {
      text-align: left !important;
      padding: 0 !important;
      line-height: 1.2 !important;
      margin-bottom: 4px !important;
      height: auto !important;
      
      > label {
        color: ${props => {
          if (props.$darkLabels) return 'black !important';
          // Check parent background color
          return 'var(--form-label-color, black) !important';
        }};
        height: auto !important;
        margin: 0 !important;
        padding: 0 !important;
        font-size: 9px !important;
      }

      /* Override Ant Design default styles with higher specificity */
      && > label.ant-form-item-required,
      && > label:not(.ant-form-item-required) {
        color: ${props => {
          if (props.$darkLabels) return 'black !important';
          // Check parent background color
          return 'var(--form-label-color, black) !important';
        }};
        height: auto !important;
        font-size: 9px !important;
      }
    }

    /* Additional specificity for Ant Design overrides */
    && .ant-form .ant-form-item .ant-form-item-label > label {
      color: ${props => {
        if (props.$darkLabels) return 'black !important';
        // Check parent background color
        return 'var(--form-label-color, black) !important';
      }};
      height: auto !important;
      font-size: 9px !important;
    }

    .ant-form-item-control {
      width: 100% !important;

      &[hidden], &.ant-form-item-hidden {
        display: none !important;
        margin: 0 !important;
        padding: 0 !important;
        height: 0 !important;
      }
    }

    .ant-form-item-required::before {
      display: none !important;
    }

    .ant-form-item-required::after {
      display: inline-block !important;
      margin-left: 4px;
      color: ${props => {
        if (props.$darkLabels) return 'black !important';
        // Check parent background color
        return 'var(--form-label-color, black) !important';
      }} !important;
      font-size: 0.8rem;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*' !important;
    }

    .dark-labels & {
      .ant-form-item-label > label {
        color: black !important;
      }
      .ant-form-item-required::after {
        color: black !important;
      }
      ${StyledLabel} {
        color: black !important;
      }
    }

    textarea + div {
      margin-bottom: 16px;
    }
  }
  
  .background-red {
    .ant-form-item-explain {
      color: white;
      font-weight: 500;
      margin-top: 0px;
      line-height: 1;
    }
  }
  
  .ant-form-item-explain {
    min-height: 12px;
    font-size: 0.7rem;
  }
`;

export const StyledTextArea = styled(TextArea)`
  min-width: 0;
  border: 1px solid #000;
  border-radius: 0px;
  padding: 5px 5px;
  margin-bottom: 2px;
`;

export const FormItem = styled(Form.Item)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 12px;

    &[hidden] {
      display: none !important;
      margin: 0 !important;
      padding: 0 !important;
      height: 0 !important;
    }

    .ant-form-item-label {
      flex: 0 0 auto;
      padding: 0 0 8px 0 !important;
      
      > label {
        height: auto !important;
        color: ${props => {
          if (props.$darkLabels) return 'black !important';
          // Check parent background color
          return 'var(--form-label-color, black) !important';
        }} !important;
        font-size: 9px !important;
        line-height: 1.2 !important;
      }
    }

    .ant-form-item-control {
      flex: 1 1 auto;
    }

    button {
      margin-top: 16px;
    }
  }
`;

export const StyledInput = styled(Input)`
  min-width: 0;
  border: 1px solid #000;
  border-radius: 0px;
  padding: 5px 10px;
  margin-bottom: 2px;

  ${({ $primary, theme }) =>
    $primary &&
    css`
      color: ${theme.colors.darkGray2};
      font-weight: 700;
    `}
`;

export const StyledSelect = styled(Select)`
  min-width: 0;
  border: 1px solid #000;
  margin-bottom: 2px;
  
  .ant-select-selector {
    height: 32px !important;
    border: none !important;
    padding: 0 10px !important;
  }
  
  .ant-select-arrow {
    color: rgba(0, 0, 0, 1) !important;
  }
  
  .ant-select-selection-placeholder {
    font-size: 1rem;
    margin: auto;
  }
  
  .ant-select-selection-item {
    font-size: 1rem;
    margin: auto;
    padding: 4px 0;
  }

  .ant-select-dropdown {
    .ant-select-item {
      padding: 8px 12px;
      font-size: 1rem;
    }
  }
`;

export const FormHeadingSection = styled.div`
  text-align: center;

  & > h2 {
    font-size: 1.3rem;
    color: #762738;
    font-family: "Montserrat";
    font-weight: bold;
  }
  span {
    text-decoration: underline;
  }
  p {
    font-family: "Montserrat";
    font-size: 1.1rem;
  }
`;
export const EEATText = styled.span`
  color: rgb(118, 39, 56);
  text-align: center;
  font-size: 0.85rem;
  line-height: 1.2;
`;

export const EEATWRAP = styled.div`
  display: flex;
  margin: 40px 4% 20px 4%;
  @media only screen and (max-width: 600px) {
  }
`;

export const EEAT = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  row-gap: 0.4em;
  padding: 0px;
  margin: 0;
  flex: 1;

  .iconImage2 {
    width: 52px !important;
    height: 64px !important;
    fill: rgb(118, 39, 56); /* Apply the fill color */
    margin-top: 8px;
  }
`;
