import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { FormContainer, FormItem, StyledInput, StyledTextArea, StyledSelect, StyledLabel, PButton } from "./styled";
import Image from 'next/image';
import { EEAT, EEATWRAP, EEATText } from "./styled";
import { jsonData } from "../../../components/LocationsMap/_data";
import BaseForm from '../../../components/Forms/BaseForm';

const FormConsultationLarge = ({
  darkLabels,
  submitButton = "Get Started",
  formId = "consultation-large",
  initialValues,
  form: externalForm,
  isOpen,
  onClose
}) => {
  const showroomOptions = React.useMemo(() => {
    return jsonData.features
      .filter(location => {
        if (location.properties.dealer === 'yes') return false;
        return location.properties.city && location.properties.state;
      })
      .map(location => {
        const city = location.properties.city.trim();
        const state = location.properties.state.trim();
        return {
          value: `${city}, ${state}`,
          label: `${city}, ${state}`,
          data: {
            ...location,
            properties: {
              ...location.properties,
              city,
              state
            }
          }
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  return (
    <BaseForm
      formId={formId}
      variant="large"
      darkLabels={darkLabels}
      submitButton={submitButton}
      initialValues={initialValues}
      form={externalForm}
      isOpen={isOpen}
      onClose={onClose}
    >
      {({ loading, form }) => (
        <FormContainer darkLabels={darkLabels}>
          <div className="form-row">
            <FormItem
              name="full_name"
              rules={[{ required: true, message: 'Please enter your name' }]}
            >
              <StyledInput placeholder="Full Name" />
            </FormItem>

            <FormItem
              name="email"
              rules={[
                { required: true, message: 'Please enter your email' },
                { type: 'email', message: 'Please enter a valid email' }
              ]}
            >
              <StyledInput placeholder="Email" />
            </FormItem>
          </div>

          <div className="form-row">
            <FormItem
              name="phone"
              rules={[{ required: true, message: 'Please enter your phone number' }]}
            >
              <StyledInput placeholder="Phone" />
            </FormItem>

            <FormItem
              name="showroom"
              rules={[{ required: true, message: 'Please select a showroom' }]}
            >
              <StyledSelect
                placeholder="Select Showroom"
                options={showroomOptions}
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </FormItem>
          </div>

          <FormItem name="message">
            <StyledTextArea
              placeholder="Message (Optional)"
              autoSize={{ minRows: 4, maxRows: 6 }}
            />
          </FormItem>

          <FormItem name="tenantId" hidden>
            <Input type="hidden" />
          </FormItem>

          <PButton
            type="primary"
            htmlType="submit"
            loading={loading}
            className="submit-button"
          >
            {submitButton}
          </PButton>

          <EEATWRAP>
            <EEAT>
              <Image
                src="/images/eeat.png"
                alt="Experience, Expertise, Authority, Trust"
                width={40}
                height={40}
              />
            </EEAT>
            <EEATText>
              <p>
                <strong>Experience, Expertise, Authority, Trust</strong>
                <br />
                Classy Closets has been designing and installing custom storage solutions since 1984
              </p>
            </EEATText>
          </EEATWRAP>
        </FormContainer>
      )}
    </BaseForm>
  );
};

FormConsultationLarge.propTypes = {
  darkLabels: PropTypes.bool,
  submitButton: PropTypes.string,
  formId: PropTypes.string,
  initialValues: PropTypes.object,
  form: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default FormConsultationLarge;
