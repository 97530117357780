import { getCurrentSessionUtm, setGoogleAdwordsInfo } from '../utils/helper';
import { eventFormSubmission } from './analytics/ga4CustomEvents';
import { getQueryFromStorage } from '../hooks/useQueryCatcher';
import TagManager from 'react-gtm-module';
import { useCookies } from 'react-cookie';

const CRM_URL = '/api/contact';
const EMAIL_URL = '/api/contact/email';
const FB_URL = '/api/crm/fb-conversion';
const PINTEREST_URL = '/api/crm/pint-conversion';

export const submitForm = async (values, options = {}) => {
  const { formId, locationContext, isIOS } = options;
  
  // Prepare base data
  const nameParts = values.full_name?.split(' ') || [];
  const firstName = nameParts[0] || '';
  const lastName = nameParts.slice(1).join(' ') || '';
  
  // Get UTM data
  const utmData = getCurrentSessionUtm() || {};
  let utmSessions = [];
  try {
    const storedUtmData = localStorage.getItem('utm_data');
    if (storedUtmData) {
      utmSessions = JSON.parse(storedUtmData);
    }
  } catch (error) {
    console.error('Error reading UTM sessions:', error);
  }

  // Get user location data
  let userLocation = { city: '', state: '' };
  try {
    const storedLocation = localStorage.getItem('usersLocationData');
    if (storedLocation) {
      userLocation = JSON.parse(storedLocation);
    }
  } catch (error) {
    console.error('Error reading user location:', error);
  }

  // Prepare submission data
  const baseData = {
    ...values,
    ...utmData,
    utm_sessions: JSON.stringify(utmSessions),
    first_name: firstName,
    last_name: lastName,
    client: 'classyclosets',
    queries: getQueryFromStorage(),
    user_id: typeof window !== 'undefined' ? window.uniqueVisitorId || 'null' : 'null',
    visitorId: typeof window !== 'undefined' ? window.uniqueVisitorId || 'null' : 'null',
    confidenceScore: typeof window !== 'undefined' ? window.confidenceScore || 0 : 0,
    fingerprint: typeof window !== 'undefined' ? window.uniqueVisitorId || 'null' : 'null',
    user_city: userLocation.city || locationContext?.city,
    user_state: userLocation.state || locationContext?.state,
    city: userLocation.city || locationContext?.city,
    state: userLocation.state || locationContext?.state,
    device_type: isIOS ? 'iOS' : (typeof window !== 'undefined' && window.innerWidth <= 768 ? 'mobile' : 'desktop'),
    form_id: formId || 'consultation-form'
  };

  // Add showroom data if present
  if (values.showroom) {
    const [showroomCity, showroomState] = values.showroom.split(', ');
    baseData.showroom_city = showroomCity;
    baseData.showroom_state = showroomState;
  }

  // Set Google Adwords info
  setGoogleAdwordsInfo(values);

  // Send analytics
  try {
    // GTM
    TagManager.dataLayer({
      dataLayer: {
        crmOrClassic: "classic",
      },
    });

    // GA4
    const eventParams = {
      email: values.email,
      phone: values.phone,
      crmOrClassic: "classic",
      page_location: window.location.href,
      page_title: document.title
    };
    await eventFormSubmission(window.location.href, "lead", eventParams);
  } catch (error) {
    console.error('Analytics error:', error);
  }

  // Make API calls
  const [crmResponse, emailResponse, fbResponse, pinterestResponse] = await Promise.allSettled([
    // CRM submission
    fetch(CRM_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(baseData)
    }).then(res => res.json()),

    // Email notification
    fetch(EMAIL_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(baseData)
    }).then(res => res.json()),

    // Facebook conversion
    fetch(FB_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        first_name: values.full_name,
        email: values.email,
        phone: values.phone,
        event_time: Date.now()
      })
    }).then(res => res.json()),

    // Pinterest conversion
    fetch(PINTEREST_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: values.email,
        phone: values.phone,
        event_time: Date.now()
      })
    }).then(res => res.json())
  ]);

  // Set converted cookie
  try {
    document.cookie = "converted=step_1; path=/; max-age=604800"; // 7 days
  } catch (error) {
    console.error('Error setting converted cookie:', error);
  }

  // Return standardized response
  return {
    success: true,
    timestamp: new Date().toISOString(),
    formData: values,
    crmResponse: crmResponse.status === 'fulfilled' ? crmResponse.value : { error: crmResponse.reason },
    emailResponse: emailResponse.status === 'fulfilled' ? emailResponse.value : { error: emailResponse.reason },
    fbResponse: fbResponse.status === 'fulfilled' ? fbResponse.value : { error: fbResponse.reason },
    pinterestResponse: pinterestResponse.status === 'fulfilled' ? pinterestResponse.value : { error: pinterestResponse.reason },
    utmData,
    location: locationContext || userLocation,
    showroom: values.showroom ? {
      city: baseData.showroom_city,
      state: baseData.showroom_state,
      tenantId: values.tenantId
    } : null
  };
}; 